<template>
    <div class="task animate__animated animate__fadeInDown">
        <div class="box" :class="order.progress==2?'gray':''" v-if="order.taskbag">
            <div class="flex top">
                <div class="flex">
                    <img class="icon" :src="order.taskbag.iconimg" alt="">
                    <span class="title">{{order.taskbag.title}}</span>
                </div>
                <div class="contract-type" >
                    <a-icon v-if="order.taskbag.type==0" type="folder-open" />
                    <a-icon v-else type="folder" />
                    {{$t('tasks.taskTypes')[order.taskbag.type]}}
                </div>
            </div>
            <div class="flex info">
                <div>
                    <div>
                        <span>{{$t('tasks.labels.amount')}}:</span><span>{{order.amount}}</span>
                    </div>
                    <div>
                        <span>{{$t('tasks.labels.profit')}}:</span><span>{{Math.round(order.amount*order.rate)/100}} ({{order.rate}}%)</span>
                    </div>
                    <div>
                        <span>{{$t('tasks.labels.time')}}:</span><span>{{$t('tasks.time',{time:order.period})}} ({{order.fromto}})</span>
                    </div>
                </div>
                <div class="progress" v-if="percentStyle=='dashboard'">
                    <a-progress type="dashboard" status="active" stroke-color="#DF474A" :width="80" :gapDegree="0" :percent="order.percent" />
                </div>
            </div>
            <!-- <div class="progress" v-if="percentStyle=='line'">
                <a-progress status="active" :stroke-color="{from: '#F2A354',to: '#DF474A',}" type="line" :gapDegree="0" :percent="order.percent" />
            </div> -->
            <div class="flex bottom">
                <span>#{{order.oid}}</span>
                <span>{{$t('tasks.progress')[order.progress]}}</span>
            </div>
        </div>
        <div v-if="order.order_goods">
            <div v-for="g,i in order.order_goods" :key="'og'+i" >
                <div class="flex order-goods">
                    <img class="img" :src="g.goods.fimg" alt="">
                    <div class="flex column txt">
                        <div class="title">{{g.goods.title}}</div>
                        <div>{{$t('tasks.labels.price')}}:{{g.goods.price}}</div>
                        <div>{{$t('tasks.labels.profit')}}:{{g.goods.price*order.rate/100}}</div>
                    </div>
                    <div class="btn-primary small clickBtn" @click="ctask(g)" :class="clicked(g)?'gray':''">
                        {{$t('tasks.checkBtn')[clicked(g)?0:1]}}
                    </div>
                </div>
                <div class="flex dots">
                    <div v-for="d,i in g.dayArr" :key="'dot'+i" class="dot" :class="g.clickedDays.indexOf(d)>=0?'on':''"></div>
                </div>
            </div>
        </div>
        <div v-else class="center">
            <a-spin /> 
        </div>      
    </div>
</template>
<script>
import { formatDate } from '../utils/funs/date';
export default {
    name: "Task",
    data() {
        return {
            fromRouteName: '',
            task_id: this.$route.params.id,
            loading: false,
            order:{},
            percentStyle: 'dashboard',//dashboard,line
        };
    },
    computed:{},
    components: {},
    mounted() {
        this.getData();
        if(this.fromRouteName == 'bag'){
            setTimeout(()=>{
                this.getData();
            },4000)
        }
    },
    beforeRouteEnter (to, from, next){
        next(vm => {
            // 通过 `vm` 访问组件实例,将值传入fromPath
            vm.fromRouteName = from.name
        })
    },
    methods: {
        getData(){
            let _this = this;
            this.loading = true;
            this.$http.call(this.$http.api.task,{
                data:{id: this.task_id}
            }).then(res=>{
                _this.loading = false;
                let resp = res.data;
                console.log(resp.data)
                if(resp.code=='200'){
                    _this.order = resp.data;
                }else{
                    _this.$message.error(resp.data);
                } 
                
            },res=>{
                console.log('something goes wrong...');
                console.log(res.response);
                if(res.response.status == '401'){
                    _this.$store.commit('setToken','');
                    _this.$router.push({name:'login'});
                }
            }).then(()=>{
                
            });
        },
        clicked(og){
            let _today = formatDate(new Date(),'Y-m-d');
            return og.clickedDays.indexOf(_today)>=0
        },
        ctask(og){
            let _this = this;
            let ogid = og.id;
            // let _today = formatDate(new Date(),'Y-m-d');
            if(this.clicked(og)){
                this.$message.error("already checked today");
                return;
            }
            this.loading = true;
            this.$http.call(this.$http.api.ctask,{
                data:{ogid: ogid}
            }).then(res=>{
                _this.loading = false;
                let resp = res.data;
                console.log(resp.data)
                if(resp.code=='200'){
                    _this.$message.success(resp.data);
                    _this.getData();
                }else{
                    _this.$message.error(resp.data);
                } 
                
            },res=>{
                console.log('something goes wrong...');
                console.log(res.response);
                if(res.response.status == '401'){
                    _this.$store.commit('setToken','');
                    _this.$router.push({name:'login'});
                }
            }).then(()=>{
                
            });
        }
    },
};
</script>
<style lang="less" scoped>
.task {
    width:100%;
    padding: 0 15px;
    text-align: left;
    .box{
        margin-bottom: 15px;
        .icon{
            width: 24px;
            height: 24px;
        }
        .title{
            padding-left: 3px;
        }
        .contract-type{
            font-size: 0.6rem;
            padding: 1px 3px;
            border-radius: 3px;
        }
        .top{
            // margin: 0 -15px;
            padding: 10px 15px;
            border-bottom: 1px solid #a8a8a8;
        }
        .bottom{
            // margin: 0 -15px;
            padding: 10px 15px;
            font-size: 0.6rem;
            // border-top: 1px solid #d8d8d8;
        }
        .info{
            padding: 15px;
        }
        
    }.gray{
        opacity: .6;
    }
    .order-goods{
        margin: 15px 0 0;
        padding: 15px 0;
        border-top: 1px solid @gray;
        .img{
            width: 50px;
            border-radius: 25px;
            margin-right: 10px;
        }
        .txt{
            flex: 1;
            align-items: flex-start;
            line-height: 25px;
            padding-right: 15px;
            .title{
                height: 50px;
                overflow: hidden;
            }
        }
        .clickBtn{
            color: @white;
        }.gray{
            background: @gray;
            color: @primary-color;
        }
    }
    .dots{
        justify-content: center;
        .dot{
            display: block;
            width: 8px;height:8px;
            border-radius: 50%;
            background: @gray;
            margin: 5px;
        }.on{
            background: @red;
        }
    }
}
</style>
