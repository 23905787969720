//根据UTC时间返回本地日期
export function getLocalDate(utcDate) {
    if (!utcDate) {
        return "";
    }

    let d = new Date(utcDate);
    let result = "";

    result += d.getFullYear() + "-";

    if (d.getMonth() < 9) {
        result += "0" + (d.getMonth() + 1) + "-";
    } else {
        result += (d.getMonth() + 1) + "-";
    }

    if (d.getDate() < 10) {
        result += "0" + d.getDate();
    } else {
        result += d.getDate();
    }

    return result;
}

//根据UTC时间返回本地时间
export function getLocalTime(utcDate) {
    if (!utcDate) {
        return "";
    }

    let d = new Date(utcDate);
    let result = "";

    if (d.getHours() < 10) {
        result += "0" + d.getHours() + ":";
    } else {
        result += d.getHours() + ":";
    }

    if (d.getMinutes() < 10) {
        result += "0" + d.getMinutes() + ":";
    } else {
        result += d.getMinutes() + ":";
    }

    if (d.getSeconds() < 10) {
        result += "0" + d.getSeconds();
    } else {
        result += d.getSeconds();
    }

    return result;
}

//根据UTC时间返回本地完整时间
export function getLocalDateTime(utcDate) {
    if (!utcDate) {
        return "";
    }

    let d = new Date(utcDate);
    let result = "";

    result += d.getFullYear() + "-";

    if (d.getMonth() < 9) {
        result += "0" + (d.getMonth() + 1) + "-";
    } else {
        result += (d.getMonth() + 1) + "-";
    }

    if (d.getDate() < 10) {
        result += "0" + d.getDate() + " ";
    } else {
        result += d.getDate() + " ";
    }

    if (d.getHours() < 10) {
        result += "0" + d.getHours() + ":";
    } else {
        result += d.getHours() + ":";
    }

    if (d.getMinutes() < 10) {
        result += "0" + d.getMinutes() + ":";
    } else {
        result += d.getMinutes() + ":";
    }

    if (d.getSeconds() < 10) {
        result += "0" + d.getSeconds();
    } else {
        result += d.getSeconds();
    }

    return result;
}

//根据参数格式化时间
export function formatDate(date, fmt) {
    let ret;
    
    if(typeof(date)==='string'){
        date = new Date(date);
    }
    const opt = {
        "Y+": date.getFullYear().toString(),        // 年
        "m+": (date.getMonth() + 1).toString(),     // 月
        "d+": date.getDate().toString(),            // 日
        "H+": date.getHours().toString(),           // 时
        "M+": date.getMinutes().toString(),         // 分
        "S+": date.getSeconds().toString()          // 秒
        // 有其他格式化字符需求可以继续添加，必须转化成字符串
    };
    for (let k in opt) {
        ret = new RegExp("(" + k + ")").exec(fmt);
        if (ret) {
            fmt = fmt.replace(ret[1], (ret[1].length == 1) ? (opt[k]) : (opt[k].padStart(ret[1].length, "0")))
        }
    }
    return fmt;
}
// 计算两个日期之间的工时
export function timeMath(dt1, dt2) {
    var regTime = /(\d{4})-(\d{1,2})-(\d{1,2})( \d{1,2}:\d{1,2})/g;
    var interval = Math.abs(Date.parse(dt1.replace(regTime, "$2-$3-$1$4")) - Date.parse(dt2.replace(regTime, "$2-$3-$1$4"))) / 1000;
    var h = Math.floor(interval / 3600);
    return h
}